@font-face {
  font-family: "inkfree";
  src: url(inkfree.ttf);
}

@font-face {
  font-family: "Britannic Light";
  src: url(britannic_light.ttf);
}

.App {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: 100%;
  display: block;
  transition: filter 500ms;
}

a {
  text-decoration: none;
  color: unset;
}

.container {
  height: 100vh;
  max-width: 1500px;
}

.resto {
  height: 33.3%;
  width: 100%;
  position: relative;
}

.resto-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Britannic Light";

  font-size: 28pt;
  transition: font-size 200ms;
  color: white;
}

.resto-title > span {
  width: 100%;
  display: block;
  text-align: center;
  background-color: rgba(0,0,0, 0.6);
  padding: 10px;
}

.resto:hover img {
  filter: blur(2px);
}

.resto:hover .resto-title {
  font-size: 32pt;
}


